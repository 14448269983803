import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, TimeoutError, catchError, filter, takeUntil, throwError, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestInterceptorService implements HttpInterceptor {

  constructor(
    private messageService: ToastrService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const routeChangedObservable = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
    
    return next.handle(req).pipe(
      timeout(15000),
      takeUntil(routeChangedObservable),
      catchError(error => {
        if (error.status == HttpStatusCode.UnprocessableEntity) {
          return new Observable<never>();
        }
        const errorMessage = this.parseErrorMessage(error);

        this.messageService.error(errorMessage, '', { enableHtml: true });
        return throwError(() => new Error(errorMessage));
      })
    )
  }

  private parseErrorMessage(error: any): string {
    let errorMessage = 'There was an unexpected problem with your request.';

    if (error instanceof TimeoutError) {
      errorMessage = 'The request has timed out.';
    } else if (error.error?.errors?.length > 0) {
      errorMessage = '<ul>';
      for (const e of error.error.errors) {
        if (e.message) {
          errorMessage += '<li>' + e.message + '</li>'
        } else {
          errorMessage += `<li>${e}</li>`
        }
      }
      errorMessage += '</ul>'
    } else if(error.error?.detail) {
      errorMessage = error.error.detail;
    } else if(error.status === 404) {
      errorMessage = 'The resource you have requested does not exist.';
    } else if(error.status === 403) {
      errorMessage = 'You do not have access to this resource.';
    } else if(error.status === 401) {
      errorMessage = 'Unauthorized user.';
    }

    return errorMessage;
  }
}
