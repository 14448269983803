<div class="padding padding-top-25 full-height">
    <mat-card>
        <mat-card-content>
            <h3>Order Details</h3>
            <div class="flex-container">
                <span class="flex-item">Reference Number:</span>
                <span class="flex-item-2">{{ order.referenceNumber }}</span>

                <span class="flex-item">Date Requested:</span>
                <span class="flex-item-2">{{ order.dateRequested | pacificDate}}</span>
            </div>
            <div class="flex-container">
                <span class="flex-item">Status:</span>
                <span class="flex-item-2 bold">{{ order.orderStatusTypeID }}</span>

                <span class="flex-item">Status Updated:</span>
                <span class="flex-item-2">{{ order.orderStatusUpdatedDate | pacificDate}}</span>
            </div>
            <div class="flex-container">
                <span class="flex-item">Jurisdiction:</span>
                <span class="flex-item-2">{{ order.jurisdiction }}</span>

                <span class="flex-item">Country:</span>
                <span class="flex-item-2">{{ order.country }}</span>
            </div>
            <div class="flex-container" *ngIf="order.lien?.qcFormTypeID">
                <span class="flex-item">QC Form Type:</span>
                <span class="flex-item-2">{{ order.lien!.qcFormTypeID! }}</span>

                <span class="flex-item"></span>
                <span class="flex-item-2"></span>
            </div>
            <div class="flex-container">
                <span class="flex-item">Order ID:</span>
                <span class="flex-item-2">{{ order.id }}</span>

                <span class="flex-item">Order Group:</span>
                <span class="flex-item-2"><a mat-flat-button color="accent" (click)="openOrderHistory()">{{ order.orderGroup?.id }}</a></span>
            </div>

            <div class="padding-top-bottom-15" *ngIf="shouldShowUserDetails">
                <mat-divider></mat-divider>
            </div>

            <div class="flex-container">
                <lib-order-requesting-user-details [order]="order" [user]="user"
                    class="flex-item">
                </lib-order-requesting-user-details>
                <lib-external-order-details *ngIf="order.id && shouldShowExternalOrderDetails"
                    [isLockedByMe]="isLockedByMe" [externalOrderDetails]="externalOrderNumber!"
                    (saveExternalOrderNumberEvent)="handleSaveExternalOrderNumberEvent($event)" class="flex-item">
                </lib-external-order-details>
            </div>
        </mat-card-content>
    </mat-card>
</div>