<div class="flex-container">
    <div class="flex-item">
        <div class="flex-container">
            <span class="flex-item">External Number:</span>
            <span class="flex-item-2">{{ result?.externalOrderNumber?.number }}</span>
        </div>
        <div class="flex-container">
            <span class="flex-item">Date Received:</span>
            <span class="flex-item-2">{{ result?.externalOrderNumber?.dateReceived |
                pacificDate:'yyyy-MM-dd HH:mm' }}</span>
        </div>
    </div>
    <div class="flex-item">
        <lib-lien-results [results]="result"></lib-lien-results>
    </div>
</div>